import axios from 'axios';

export const Configuration= {
  'ApplicationApiDomain': process.env.ApplicationApiDomain,
  'ApplicationWrapperApiDomain': process.env.ApplicationWrapperApiDomain,
  'PaymentApiDomain': process.env.PaymentApiDomain,
  'PaymentWebDomain': process.env.PaymentWebDomain,
  'SignupApiDomain': process.env.SignupApiDomain,
  'AssessmentApiDomain': process.env.AssessmentApiDomain,
  'PricingApiDomain': process.env.PricingApiDomain,
  'BulkUploadDomain': process.env.BulkUploadDomain,
  'AgentsApi': process.env.AgentsApi,
};
/**
TODO:
**/
class RestApi {
  /**
   * Creates an instance of RestApi.
   * @memberof RestApi
   */
  constructor() {
    // 'https://api.shawacademy.com' Configuration.ApplicationApiDomain;
    // 'https://chargebee-wrapper-api.shawacademy.com' Configuration.PaymentApiDomain
    // 'https://goapi-wrapper.shawacademy.com';
    this.apiDomain = Configuration.ApplicationApiDomain;
    this.apiWrapperDomain = Configuration.ApplicationWrapperApiDomain;
    this.apiSubscriptionDomain = Configuration.PaymentApiDomain;
    this.paymentWebDomain = Configuration.PaymentWebDomain;
    this.signupApiDoman = Configuration.SignupApiDomain;
    this.typeFormApi = 'https://api.typeform.com/forms/';
    this.freshSalesApiDomain = Configuration.deleteFromFreshSales;
    this.assessmentApiDomain = Configuration.AssessmentApiDomain;
    this.PricingApiDomain = Configuration.PricingApiDomain;
    this.BulkUploadDomain = Configuration.BulkUploadDomain;
    this.AgentsApi = Configuration.AgentsApi;
  }

  /**
   *
   *
   * @return {*}
   * @memberof RestApi
   */
  getAgentsApiDomain() {
    return this.AgentsApi;
  }
  /**
   *
   *
   * @return {*}
   * @memberof RestApi
   */
  getPaymentApiDomain() {
    return this.apiSubscriptionDomain;
  }
  /**
   *
   *
   * @return {*}
   * @memberof RestApi
   */
  getPaymentWebDomain() {
    return this.paymentWebDomain;
  }
  /**
   *
   *
   * @return {*}
   * @memberof RestApi
   */
  getApiDomain() {
    return this.apiDomain;
  }

  /**
  *
  *
  * @return {*}
  * @memberof RestApi
  */
  getApiWrapperDomain() {
    return this.apiWrapperDomain;
  }
  /**
  *
  *
  * @return {*}
  * @memberof RestApi
  */
  getSignupApiDomain() {
    return this.signupApiDoman;
  }

  /**
   *
   *
   * @return {*}
   * @memberof RestApi
   */
  getTypeFormDomain() {
    return this.typeFormApi;
  }
  /**
   *
   *
   * @return {*}
   * @memberof RestApi
   */
  getAssessmentApiDomain() {
    return this.assessmentApiDomain;
  }

  /**
   *
   *
   * @return {*}
   * @memberof RestApi
   */
  getPricingApiDomain() {
    return this.PricingApiDomain;
  }

  /**
   *
   *
   * @return {*}
   * @memberof RestApi
   */
  getUploadApiDomain() {
    return this.BulkUploadDomain;
  }

  /**
  * Returns an promise
  * @param {string} createUrl
  * @param {string} parameters
  * @return {Promise}
  */
  create(createUrl, parameters) {
    return axios.post(createUrl, parameters);
  }
  /**
  * Returns an promise
  * @param {string} updateUrl
  * @param {string} parameters
  * @return {Promise}
  */
  update(updateUrl, parameters) {
    return axios.patch(updateUrl, parameters);
  }
  /**
   *
   *
   * @param {*} deleteUrl
   * @param {*} parameters
   * @return {*}
   * @memberof RestApi
   */
  delete(deleteUrl, parameters) {
    return axios.delete(deleteUrl, parameters);
  }
  /**
  * Returns an promise
  * @param {listUrl} listUrl
  * @return {Promise}
  */
  list(listUrl) {
    return axios.get(listUrl);
  }
}
/**
 *
 *
 * @class FireBaseAuth
 * @extends {RestApi}
 */
class FireBaseAuth extends RestApi {
  /**
   TODO:
   @param {studentId} studentId
  **/
  constructor(studentId) {
    super();
    this.studentId=studentId;
  }


  /**
   *
   *
   * @param {*} regId
   * @return {*} authToken
   * @memberof FireBaseAuth
   */
  authenticateRecording(regId) {
    const domain = super.getApiDomain();
    const listUrl=`${domain}/v2/auth/firebase?registration_id=${regId}`;
    return super.list(listUrl);
  }
}
/**
 TODO:
**/
class Addons extends RestApi {
  /**
   TODO:
   @param {studentId} studentId
  **/
  constructor(studentId) {
    super();
    this.studentId=studentId;
  }
  /**
  * Returns an promise
  * @return {Promise}
  */
  list() {
    const domain = super.getApiDomain();
    const listUrl=`${domain}/v2/students/${this.studentId}/addons`;
    return super.list(listUrl);
  }
  /**
   *
   *
   * @return {*}
   * @memberof Addons
   */
  listLead() {
    const domain = super.getApiDomain();
    const listUrl=`${domain}/v2/students/${this.studentId}`;
    return super.list(listUrl);
  }


  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Addons
   */
  purchaseAddon(parameters) {
    const domain = super.getUploadApiDomain();
    const url = `${domain}/purchase`;
    return super.create(url, parameters);
  }

  /**
   *
   *
   * @param {*} product
   * @param {*} countryCode
   * @return {*}
   * @memberof Addons
   */
  getPrices(product, countryCode) {
    const domain = super.getPricingApiDomain();
    const url = `${domain}/price?product=${product
    }&country_code=${countryCode}`;
    return super.list(url);
  }

  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Addons
   */
  giftAddon(parameters) {
    const domain = super.getPricingApiDomain();
    const url = `${domain}/orders`;
    return super.create(url, parameters);
  }

  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Addons
   */
  upgradeSubscription(parameters) {
    const domain=super.getPricingApiDomain();
    const url=`${domain}/subscriptions/`;
    return super.update(url, parameters);
  }

  /**
   * @return {*}
   * @memberof Addons
   */
  getUpgradePrices() {
    const domain = super.getPaymentWebDomain();
    const url = `${domain}/assets/json/plans/v17/prices.json`;
    return super.list(url);
  }

  /**
   *
   *
   * @return {*}
   * @memberof Addons
   */
  listLeadAndRegistration() {
    const domain = super.getApiDomain();
    const listUrl=
      domain + '/v2/students/' + this.studentId +
      '?include=registration,subscriptions';
    return super.list(listUrl);
  }
}
/**
 TODO:
**/
class Auth extends RestApi {
  /**
  * Returns an promise
  * @param {parameters} parameters
  * @return {Promise}
  */
  authenticate(parameters) {
    const domain = super.getApiDomain();
    const url = `${domain}/v2/auth/token`;
    return super.create(url, parameters);
  }
  /**
  * Returns an promise
  * @param {parameters} parameters
  * @return {Promise}
  */
  forgotPasswordRequest(parameters) {
    const domain = super.getApiDomain();
    const url = `${domain}/v2/users/forgot-password`;
    return super.create(url, parameters);
  }
  /**
  * Returns an promise
  * @param {parameters} parameters
  * @return {Promise}
  */
  resetPasswordRequest(parameters) {
    const domain = super.getApiDomain();
    const url = `${domain}/v2/users/reset-password`;
    return super.create(url, parameters);
  }
}
/**
 TODO:
**/
class Registrations extends RestApi {
  /**
   TODO:
   @param {studentId} studentId
  **/
  constructor(studentId) {
    super();
    this.studentId=studentId;
  }
  /**
  * Returns an promise
  * @return {Promise}
  */
  list() {
    const domain = super.getApiDomain();
    const studentId=this.studentId;
    const listUrl=`${domain}/v2/students/${studentId
    }/course-registrations/?include=modules`;
    return super.list(listUrl);
  }
  /**
   *
   *
   * @param {*} timeSince
   * @param {*} timeUntil
   * @return {*}
   * @memberof Registrations
   */
  lessonSchedule(timeSince, timeUntil) {
    const domain = super.getApiDomain();
    const studentId = this.studentId;
    const lessonListUrl = `${domain}/v2/students/${studentId}/lesson-schedules`+
      `?filter[lesson-schedules.start_time][since]=${timeSince}`+
      `&filter[lesson-schedules.start_time][until]=${timeUntil}`;
    return super.list(lessonListUrl);
  }
}
/**
 TODO:
**/
class Schedule extends RestApi {
  /**
   TODO:
   @param {registrationId} registrationId
  **/
  constructor(registrationId) {
    super();
    this.registrationId=registrationId;
  }
  /**
  * Returns an promise
  * @return {Promise}
  */
  get() {
    const domain = super.getApiDomain();
    const url = `${domain}/v2/registrations/${this.registrationId}`;
    return super.list(url);
  }
  /**
  * Returns an promise
  * @param {parameters} parameters
  * @return {Promise}
  */
  create(parameters) {
    const domain = super.getApiDomain();
    const url=`${domain}/v2/registrations/`;
    return super.create(url, parameters);
  }
  /**
  * Returns an promise
  * @param {parameters} parameters
  * @return {Promise}
  */
  update(parameters) {
    const domain = super.getApiDomain();
    const url=`${domain}/v2/registrations/${this.registrationId}`;
    return super.update(url, parameters);
  }
  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Schedule
   */
  delete(parameters) {
    const domain = super.getApiDomain();
    const url=`${domain}/v2/registrations/${this.registrationId}`;
    return super.delete(url, parameters);
  }
}
/**
 TODO:
**/
class Student extends RestApi {
  /**
   TODO:
   @param {studentId} studentId
  **/
  constructor(studentId) {
    super();
    this.studentId = studentId;
  }
  /**
  * Returns an promise
  * @return {Promise}
  */
  get() {
    const domain = super.getApiDomain();
    const listUrl = `${domain}/v2/students/${this.studentId}`;
    return super.list(listUrl);
  }

  /**
  * Returns an promise
  * @return {Promise}
  */
  getCreditsBalance() {
    const domain = super.getApiDomain();
    const listUrl = `${domain}/v2/students/${this.studentId}/credit-balance`;
    return super.list(listUrl);
  }

  /**
  * Returns an promise
  * @param {parameters} parameters
  * @return {Promise}
  */
  update(parameters) {
    const domain = super.getApiDomain();
    const url=`${domain}/v2/students/${this.studentId}`;
    return super.update(url, parameters);
  }

  /**
   *
   *
   * @return {*}
   * @memberof Addons
   */
  listLeadAndSubscription() {
    // Testing same domain proxy
    const domain = super.getApiDomain();
    const listUrl = `${domain}/v2/students/` +
      `${this.studentId}?include=subscriptions`;
    return super.list(listUrl);
  }


  /**
  * Returns an promise
  * @param {parameters} parameters
  * @return {Promise}
  */
  updatePassword(parameters) {
    const domain = super.getApiDomain();
    const url=`${domain}/v2/users/${this.studentId}/update-password`;
    return super.update(url, parameters);
  }

  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Student
   */
  uploadProfilePic(parameters) {
    const domain = super.getApiDomain();
    const url=`${domain}/v2/students/${this.studentId}/profile_pic`;
    return super.create(url, parameters);
  }
}
/**
 *
 *
 * @export
 * @class Subscription
 * @extends {RestApi}
 */
export class Subscription extends RestApi {
  /**
   * Creates an instance of Subscription.
   * @param {*} subId
   * @memberof Subscription
   */
  constructor(subId) {
    super();
    this.subId=subId;
  }

  /**
   *
   * @param {*} parameters
   *
   * @return {*}
   * @memberof Subscription
   */
  resumeMembership(parameters) {
    const domain = super.getPricingApiDomain();
    const url=`${domain}/resume-subscription`;
    return super.create(url, parameters);
  }


  /**
   *
   *
   * @param {*} studentId
   * @return {*}
   * @memberof Subscription
   */
  reactivateWithTrial(studentId) {
    const domain = super.getAgentsApiDomain();
    const param = `chargebee-wrapper/reactivate-subscription`;
    const url=`${domain}${param}/?student_id=${studentId}`;
    return super.list(url);
  }

  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Subscription
   */
  updateSubscription(parameters) {
    const domain = super.getPricingApiDomain();
    // const url=`${domain}/reactivate-subscription`;
    const url=`${domain}/subscriptions/${this.subId}`;
    return super.update(url, parameters);
  }
  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Subscription
   */
  pauseSubscription(parameters) {
    const domain = super.getPricingApiDomain();
    const url=`${domain}/pause-subscription`;
    return super.create(url, parameters);
  }
  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Subscription
   */
  cancelSubscription(parameters) {
    const domain = super.getPaymentApiDomain();
    const url=`${domain}/subscriptions/${this.subId}`;
    return super.delete(url, parameters);
  }

  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Subscription
   */
  cancelSubscriptionForPayfast(parameters) {
    const domain = super.getPricingApiDomain();
    const url=`${domain}/subscriptions/${this.subId}`;
    return super.delete(url, parameters);
  }

  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Subscription
   */
  createChargebeeSession(parameters) {
    const domain = super.getPaymentApiDomain();
    const url=`${domain}/sessions`;
    return super.create(url, parameters);
  }
}
/**
 *
 *
 * @export
 * @class Signup
 * @extends {RestApi}
 */
export class Signup extends RestApi {
  /**
   * Creates an instance of Signup.
   * @param {*} studentId
   * @memberof Signup
   */
  constructor(studentId) {
    super();
    this.studentId=studentId;
  }

  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Signup
   */
  signup(parameters) {
    const domain = super.getSignupApiDomain();
    const url=`${domain}/signup`;
    return super.create(url, parameters);
  }

  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Signup
   */
  createPassword(parameters) {
    const domain = super.getApiDomain();
    const url=`${domain}/v2/users/${this.studentId}`;
    return super.update(url, parameters);
  }
  /**
   *
   *
   * @param {*} authToken
   * @return {*}
   * @memberof Signup
   */
  getStudentDetails(authToken) {
    const domain = super.getSignupApiDomain();
    const url=`${domain}/me/?auth_token=${authToken}`;
    return super.list(url);
  }
}
/**
 *
 *
 * @export
 * @class Signup
 * @extends {RestApi}
 */
export class Profile extends RestApi {
  /**
   * Creates an instance of Profile.
   * @param {*} profileId
   * @memberof Profile
   */
  constructor(profileId) {
    super();
    this.profileId=profileId;
  }

  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Profile
   */
  createProfile(parameters) {
    const domain = super.getApiDomain();
    const url=`${domain}/v2/studentprofiles`;
    return super.create(url, parameters);
  }
  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Signup
   */
  updateProfile(parameters) {
    const domain = super.getApiDomain();
    const url=`${domain}/v2/studentprofiles/${this.profileId}`;
    return super.update(url, parameters);
  }
}

/**
 Student Detail API call on email search
**/
class CsAgentStudentDetail extends RestApi {
  /**
   * Creates an instance of StudentDetail.
   * @param {*} studentEmail
   * @memberof StudentDetail
   */
  constructor(studentEmail) {
    super();
    this.studentEmail = studentEmail;
  }
  /**
  * Returns an promise
  * @return {Promise}
  */
  studentDetails() {
    const domain = super.getApiDomain();
    const studentDetailsUrl=
    `${domain}/v2/students/?filter[student.email]=${this.studentEmail}`;
    return super.list(studentDetailsUrl);
  }

  /**
  * Returns an promise
  * @return {Promise}
  */
  studentDetailsWithRegistrations() {
    const domain = super.getApiDomain();
    const filterUrl='/v2/students/?filter[student.email]=';
    const studentDetailsUrl=
    `${domain}${filterUrl}${this.studentEmail}&include=registrations`;
    return super.list(studentDetailsUrl);
  }
}

/**
 Handling all apis for subscriptions related to CS Agent.
**/
class CsAgentSubscriptions extends RestApi {
  /**
   * Creates an instance of StudentDetail.
   * @param {*} studentId
   * @param {*} subscriptionId
   * @memberof CsAgentSubscriptions
   */
  constructor(studentId, subscriptionId) {
    super();
    this.studentId = studentId;
    this.subscriptionId = subscriptionId;
  }
  /**
  * Returns an promise
  * @return {Promise}
  */
  getAllSubcriptions() {
    const domain = super.getApiDomain();
    const getAllSubscriptionsUrl=
    `${domain}/v2/students/${this.studentId}/subscriptions`;
    return super.list(getAllSubscriptionsUrl);
  }
  /**
  * Returns an promise
  * @param {*} parameters
  * @return {Promise}
  */
  cancelSubscription(parameters) {
    const domain = super.getPaymentApiDomain();
    const cancelSubscriptionUrl=
    `${domain}/subscriptions/${this.subscriptionId}`;
    return super.delete(cancelSubscriptionUrl, parameters);
  }
  /**
  * Returns an promise
  * @param {*} parameters
  * @return {Promise}
  */
  giftMembership(parameters) {
    const domain = super.getApiDomain();
    const giftUrl=`${domain}/v2/subscriptions/`;
    return super.create(giftUrl, parameters);
  }
}
/**
 Handling all apis for BasicInfo related to CS Agent.
**/
class CsAgentStudentDetails extends RestApi {
  /**
   * Creates an instance of StudentDetail.
   * @param {*} studentId
   * @memberof CsAgentStudentDetails
   */
  constructor(studentId) {
    super();
    this.studentId = studentId;
  }
  /**
  * Returns an promise
  * @param {parameters} parameters
  * @return {Promise}
  */
  deleteStudent(parameters) {
    const domain = super.getApiDomain();
    const deleteStudentUrl=
    `${domain}/v2/students/${this.studentId}`;
    return super.update(deleteStudentUrl, parameters);
  };
  /**
  * Returns an promise which gives list of registered courses
  * @return {Promise}
  */
  getStudentRegistrations() {
    const domain = super.getApiDomain();
    const getRegistrationsUrl=
    `${domain}/v2/students/${this.studentId}/registrations`;
    return super.list(getRegistrationsUrl);
  }
  /**
  * Returns an promise which deletes student's registered courses
  * @param {parameters} parameters
  * @return {Promise}
  */
  deleteRegisteredCourse(parameters) {
    const domain = super.getApiDomain();
    const deleteRegisteredCourseUrl=
    `${domain}/v2/registrations/${this.studentId}`;
    return super.delete(deleteRegisteredCourseUrl, parameters);
  }

  /**
   *
   *
   * @param {*} params
   * @return {*} assignmentData
   * @memberof CsAgentStudentDetails
   */
  getAssignmentInfo(params) {
    const domain = super.getAssessmentApiDomain();
    const listUrl=`${domain}/course-assessments?reg_id=${params}`;
    return super.list(listUrl, params);
  }
}

/**
 Reactivation agent api calls
**/
class ReactivationAgent extends RestApi {
  /**
  * Returns an promise
  * @return {Promise}
  * @param {*} params
  */
  invoiceAddons(params) {
    const domain = super.getPaymentApiDomain();
    const addonInvoiceUrl=
    `${domain}/invoice-addons`;
    return super.create(addonInvoiceUrl, params);
  }
}

/**
 *
 *
 * @class Assessments
 * @extends {RestApi}
 */
class Assessments extends RestApi {
  /**
   TODO:
   @param {studentId} studentId
  **/
  constructor(studentId) {
    super();
    this.studentId=studentId;
  }
  /**
   *
   *
   * @param {*} params
   * @return {*}
   * @memberof Assessments
   */
  pushData(params) {
    const domain = super.getApiWrapperDomain();
    const listUrl=`${domain}/assessment-results`;
    return super.create(listUrl, params);
  };

  /**
   *
   *
   * @param {*} params
   * @return {*} returns assignment list
   * @memberof Assessments
   */
  getAssignmentData(params) {
    const domain = super.getAssessmentApiDomain();
    const listUrl=`${domain}/course-assessments?reg_id=${params}`;
    return super.list(listUrl, params);
  }

  /**
   *
   *
   * @param {*} params
   * @return {*} returns pricing list
   * @memberof Assessments
   */
  getPricingInfo(params) {
    const domain = super.getPricingApiDomain();
    const getPriceUrl=
      `${domain}/price?product=module-assessment-retake&country_code=${params}`;
    return super.list(getPriceUrl, params);
  }
}

/**
 Handling api for fresh sales related to CS Agent.
**/
class FreshSales extends RestApi {
  /**
   * Creates an instance of StudentDetail.
   * @param {*} studentEmail
   */
  constructor(studentEmail) {
    super();
    this.studentEmail = studentEmail;
  }
  /**
  * Returns an promise
  * @return {Promise}
  */
  deleteFromFreshSales() {
    const domain = this.freshSalesApiDomain;
    const studentDetailsUrl=
  `${domain}/user?email=${this.studentEmail}`;
    return super.delete(studentDetailsUrl);
  }
}
/**
 Handling api for certificate data
**/
class Certificate extends RestApi {
  /**
   * Creates an instance of Certificate.
   */
  constructor() {
    super();
  }

  /**
   *
   *
   * @param {*} formId
   * @param {*} responseId
   * @return {*}
   * @memberof Certificate
   */
  getCertificateData(formId, responseId) {
    const domain = super.getAssessmentApiDomain();
    const certificateDetailsUrl=
    `${domain}/certificate/?form_id=${formId}&response_id=${responseId}`;
    return super.list(certificateDetailsUrl).then((res) => (res.data));
  }
}

/**
 Handling all apis for Bulk upload related to CS Agent.
 */
class CsAgentBulkUpload extends RestApi {
  /**
   * Creates an instance of CsAgentBulkUpload.
   * @param {*} studentId
   * @memberof CsAgentBulkUpload
   */
  constructor(studentId) {
    super();
    this.studentId = studentId;
  }
  /**
   *
   * @param {*} parameters
   *
   * @return {*}
   * @memberof CsAgentBulkUpload
   */
  uploadExcel(parameters) {
    const domain = super.getUploadApiDomain();
    const uploadExcelUrl=
    `${domain}/orders`;
    return super.create(uploadExcelUrl, parameters);
  }
}
/**
 TODO:
**/
class Webinar extends RestApi {
  /**
   TODO:
   @param {registrationId} registrationId
  **/
  constructor(registrationId) {
    super();
    this.registrationId=registrationId;
  }
  /**
  * Returns an promise
  * @return {Promise}
  */
  get() {
    const domain = super.getApiDomain();
    const url = `${domain}/v2/registrations/${this.registrationId}`;
    return super.list(url);
  }
}
/**
 *
 *
 * @class AddMember
 * @extends {RestApi}
 */
class AddMember extends RestApi {
  /**
   TODO:
   @param {studentId} studentId
  **/
  constructor(studentId) {
    super();
    this.studentId=studentId;
  }
  /**
   *
   *
   * @param {*} subId
   * @return {*}
   * @memberof AddMember
   */
  listMembers(subId) {
    const domain = super.getPaymentApiDomain();
    const url = `${domain}/subscriptions/${subId
    }/members/?student_id=${this.studentId}`;
    return super.list(url);
  }

  /**
   *
   *
   * @param {*} subId
   * @param {*} memberId
   * @return {*}
   * @memberof AddMember
   */
  removeMember(subId, memberId) {
    const domain = super.getPaymentApiDomain();
    const parameters = {
      'data': {
        'student_id': this.studentId,
      },
    };
    const url = `${domain}/subscriptions/${subId
    }/members/${memberId}`;
    return super.delete(url, parameters);
  }
  /**
   *
   *
   * @param {*} subId
   * @param {*} memberData
   * @return {*}
   * @memberof AddMember
   */
  addMember(subId, memberData) {
    const domain = super.getPaymentApiDomain();
    const data = {
      'student_id': memberData.id,
      'member': {
        'first_name': memberData.first_name,
        'last_name': memberData.last_name,
        'email': memberData.email,
      },
    };
    const url = `${domain}/subscriptions/${subId}/members`;
    return super.create(url, data);
  }
}
/**
 * Handling all apis for Leaderboard.
 */
class Leaderboard extends RestApi {
  /**
   * Creates an instance of Leaderboard.
   * @param {*} studentId
   * @memberof Leaderboard
   */
  constructor(studentId) {
    super();
    this.studentId = studentId;
  }
  /**
   * @param {*} parameters
   * @return {*}
   * @memberof Leaderboard
   */
  getXp(parameters) {
    const domain = super.getApiDomain();
    const url = `${domain}/v2/track-activity`;
    return super.create(url, parameters);
  }

  // /**
  //  * @memberof Leaderboard
  //  * @return {*}
  //  */
  // getUserActivities() {
  //   const domain = super.getApiDomain();
  //   const url = `${domain}/students/${this.studentId}/activities`;
  //   return super.list(url);
  // }
}
/**
 *
 *
 * @class Prices
 * @extends {RestApi}
 */
class Prices extends RestApi {
  /**
   * Creates an instance of Prices.
   *
   * @memberof Prices
   */
  constructor() {
    super();
  }
  /**
   * @param {*} parameters
   * @return {*}
   * @memberof Prices
   */
  getPrices() {
    const domain = super.getPaymentWebDomain();
    const url = `${domain}/assets/json/plans/v17/prices.json`;
    return super.list(url);
  }

  /**
   *
   *
   * @param {*} parameters
   * @return {*}
   * @memberof Prices
   */
  switchSubscription(parameters) {
    const domain=super.getPricingApiDomain();
    const url=`${domain}/subscriptions/`;
    return super.update(url, parameters);
  }
}

export default {
  Addons,
  Auth,
  Registrations,
  Profile,
  Schedule,
  Assessments,
  Student,
  Subscription,
  CsAgentStudentDetail,
  Signup,
  CsAgentSubscriptions,
  CsAgentStudentDetails,
  ReactivationAgent,
  FreshSales,
  Certificate,
  FireBaseAuth,
  CsAgentBulkUpload,
  Webinar,
  AddMember,
  Leaderboard,
  Prices,
};
